// Libraries
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
// Services
import { isInCurrentMonth } from '../../../services/helpers';
// Components
import Checkbox from '../../base/Checkbox';
import AddTimeTrackingButton from '../../topBar/AddTimeTrackingButton';
// Constants
import {
  FINALIZATION_CHECKLIST, DATE_FORMAT, TIME_FORMAT, USER_BILLING_STATUSES, TENANT_FEATURES_NAMES,
} from '../../../constants/constants';
import FinalizeButton from './FinalizeButton';


export const FinalizeChecklist = (props) => {
  const { refetchLoadPatient, refetchFinalizeAlerts } = props;
  const {
    patient: {
      status, finalizeAlerts,
      carePlan: {
        recentCpDate, recentCpBy, recentCpName, permissions, carePlanId,
      } = {},
    } = {}, user, timezone, tenant: { features, isReadOnly = false },
  } = useSelector(state => state);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [sendSatisfactionSurvey, setSendSatisfactionSurvey] = useState(false);

  let finalizeButton;
  let lastFinalized;

  const isOneCarePlanPerMonth = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ONE_CARE_PLAN_PER_MONTH.name)
      .enabled;

  const isOneCarePlanPerMonthAllowed = () => (
    !isOneCarePlanPerMonth ? true : !isInCurrentMonth(recentCpDate));

  if (carePlanId) {
    if (permissions && permissions.canBeFinalized) {
      const isCCstatus = status === USER_BILLING_STATUSES[0];
      let lastFinalizedDate = '';

      finalizeButton = (
        <FinalizeButton
          refetchLoadPatient={refetchLoadPatient}
          sendSatisfactionSurvey={sendSatisfactionSurvey}
          setSendSatisfactionSurvey={setSendSatisfactionSurvey}
          refetchFinalizeAlerts={refetchFinalizeAlerts}
          disabled={isReadOnly || !isCheckAll || !isOneCarePlanPerMonthAllowed()}
          data-test="finalizeChecklist_saveBtn"
        />
      );

      if (recentCpDate && isCCstatus) {
        const tenantTimezone = timezone || moment.tz.guess();
        const formattedCpDate = moment(recentCpDate, DATE_FORMAT.FULL_SERVER_WITH_TIME);
        const now = moment().tz(tenantTimezone).format(DATE_FORMAT.FULL_SERVER_WITH_TIME);
        const isSameDay = moment(now).isSame(formattedCpDate, 'day');
        const isSameYear = moment(now).isSame(formattedCpDate, 'year');

        if (formattedCpDate.isValid()) {
          if (isSameDay) {
            lastFinalizedDate = `today at ${formattedCpDate.format(TIME_FORMAT.TIME_WITH_TIMEZONE)}`;
          } else if (isSameYear) {
            lastFinalizedDate = `${formattedCpDate.format(DATE_FORMAT.MONTH_WITH_DAY)}`;
          } else {
            lastFinalizedDate = formattedCpDate.format(DATE_FORMAT.FULL_WITH_STING_MONTH);
          }
        }

        let lastFinalizedBy = '';
        if (recentCpBy) {
          if (recentCpBy === user.id) {
            lastFinalizedBy = ' by you';
          } else if (recentCpName) {
            lastFinalizedBy = ` by ${recentCpName}`;
          }
        }

        lastFinalized = `Last finalized ${lastFinalizedDate}${lastFinalizedBy}`;
      }
    }
  }

  const handleSelectAll = () => {
    setIsCheckAll(true);
    setIsCheck(FINALIZATION_CHECKLIST.map(li => li.id));
  };

  const handleSelectOne = (fieldKey, value) => {
    setIsCheck([...isCheck, fieldKey]);
    if (!value) {
      setIsCheck(isCheck.filter(check => check !== fieldKey));
    }
  };

  useEffect(() => {
    if (isCheck.length === FINALIZATION_CHECKLIST.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [isCheck]);

  const checkBoxStyles = { formGroup: 'text-left' };
  const finalizationRows = FINALIZATION_CHECKLIST.map((item, index) => (
    <div className="col-12 my-2" key={index}>
      <Checkbox
        type="checkbox"
        styles={checkBoxStyles}
        label={item.displayName}
        fieldKey={item.id}
        labelStyle="ml-3"
        submitCallback={handleSelectOne}
        initialValue={isCheck.includes(item.id)}
        id={item.id}
        name={`factors[${index}].value`}
        data-test={`finalizeChecklist_checkbox${item.id}`}
        disabled={isReadOnly}
      />
    </div>
  ));
  return (
    <div className="col-12 ml-3">
      <div className="row m-0">
        <div className="col-12">
          <h5>Finalization Checklist</h5>
        </div>
      </div>
      <div className="row no-gutters p-4" data-test="finalizeChecklist_checklist">
        {finalizationRows}
      </div>
      { finalizeAlerts && finalizeAlerts.dueForSatisfactionSurvey && (
        <div className="row no-gutters p-4" data-test="finalizeChecklist_checklist">
          <div>
            <p>Patient is due for a satisfaction survey, would you like to send one now?</p>
            <Checkbox
              type="checkbox"
              styles={checkBoxStyles}
              label="Yes, please send a text message with the survey link"
              labelStyle="ml-3"
              submitCallback={() => setSendSatisfactionSurvey(!sendSatisfactionSurvey)}
              data-test="finalizeChecklist_checkboxSSurvey"
              disabled={isReadOnly}
            />
          </div>
        </div>)}
      <div className="row m-0">
        <div className="col-12 no-gutters text-right">
          <Button variant="light" className="mr-2" onClick={handleSelectAll} data-test="finalizeChecklist_confirmBtn" disabled={isReadOnly}>Confirm All</Button>
          <AddTimeTrackingButton />
          {finalizeButton}
        </div>
      </div>
      <div className="col-12 mt-2 text-right">
        <span className="text-ccm-gray" data-test="finalizeChecklist_lastFinalizedText">{lastFinalized}</span>
      </div>
      {!isOneCarePlanPerMonthAllowed() && (
        <div className="col-12 mt-2 text-right">
          <span className="text-ccm-red">Care plan may only be finalized once each calendar month</span>
        </div>
      )}
    </div>
  );
};

export default FinalizeChecklist;
