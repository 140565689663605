import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import AuditingModal from '../patient/auditing/AuditingModal';
import { getPatientId } from '../../services/helpers';

export class AddTimeTrackingButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuditingModalOpen: false,
    };
  }

  renderAuditingActivityButton = () => {
    const {
      patient,
      isReadOnly = false,
    } = this.props;
    const patientId = getPatientId(patient);
    const { isAuditingModalOpen } = this.state;

    return (
      <Fragment>
        <Button
          size="sm"
          className="m-2"
          variant="primary"
          onClick={() => this.setState({
            isAuditingModalOpen: true,
          })}
          data-test="addTimeTracking_openAuditModalButton"
          disabled={isReadOnly}
        >
          <i className="mr-2 bi-plus-lg" />
          Add Time Tracking
        </Button>
        <AuditingModal
          patientId={patientId}
          initialActivity={null}
          isModalOpen={isAuditingModalOpen}
          setIsModalOpen={isOpened => this.setState({
            isAuditingModalOpen: isOpened,
          })}
          data-test="addTimeTracking_auditingModal"
        />
      </Fragment>
    );
  };

  render() {
    const { patient } = this.props;

    return (
      <Fragment>
        {patient?.identifiers && (
          this.renderAuditingActivityButton()
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
    isReadOnly: state.tenant && state.tenant.isReadOnly,
  };
}

export default connect(mapStateToProps)(AddTimeTrackingButton);
